// create a Counter module here
// it will be imported in modules inside store's index.js

export const Counter = {
  namespaced: true,     // this allows you to reference this module by its name Counter

  state: {
    // fillerWords to thee state here
    fillerWords: null
  },

  mutations: {
    SET_FILLER_WORDS(state, fillerWords) {
      state.fillerWords = fillerWords;
    },

    INCREASE_FILLER_WORDS(state, index) {
      state.fillerWords[index]['wordCount'] += 1;
    },

    DECREASE_FILLER_WORDS(state, index) {
      if(state.fillerWords[index]['wordCount'] > 0){
        state.fillerWords[index]['wordCount'] -= 1;
      }
    }
  },

  actions: {
    setFillerWords({ commit }, fillerWords) {
      commit('SET_FILLER_WORDS', fillerWords);
    },

    increaseFillerWords({ commit }, fillerWords, index) {
      commit('INCREASE_FILLER_WORDS', fillerWords, index);
    },

    decreaseFillerWords({ commit }, fillerWords, index) {
      commit('DECREASE_FILLER_WORDS', fillerWords, index);
    }
  }
  
}