<template>
  <div class="p-2 lg:w-1/4 md:w-1/2 w-full relative">
    <div class="absolute right-3 top-3">
      <button @click="$emit('del-user')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-red-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div
      class="
        h-full
        flex flex-col
        items-center
        border-gray-200 border
        p-4
        rounded-lg
        shadow-lg
        bg-white
      "
    >
      <div class="grid grid-cols-3 w-full">
        <div class="mx-auto">
          <img
            alt="team"
            class="
              w-16
              h-16
              bg-gray-100
              object-cover object-center
              flex-shrink-0
              rounded-full
            "
            :src="require(`@/assets/roles/profile-pic.svg`)"
          />
        </div>
        <div class="col-span-2 mx-auto">
          <p class="text-gray-800 title-font font-medium text-center">
            {{ name }}
          </p>
          <p class="text-gray-400 title-font font-normal text-center">
            {{ role.role }}
          </p>
        </div>
        <div class="col-span-3">
          <hr class="mt-3 mb-3" />
          <div class="flex">
            <button
              type="button"
              class="
                flex-1
                mx-2
                transition
                duration-300
                bg-red-300
                ring ring-red-300
                rounded-full
                text-lg
                shadow-lg
                text-white
                hover:bg-red-400
              "
              @click="resetTimer"
            >
              <i class="fas fa-history"></i>
            </button>
            <p :class="timerBgClass">
              {{ formattedElapsedTime }}
            </p>
            <button
              type="button"
              class="
                flex-1
                mx-2
                bg-green-500
                ring ring-green-300
                rounded-full
                text-lg
                shadow
                text-white
                hover:bg-green-600
              "
              @click="startTimer"
              v-if="!timerOn"
            >
              <i class="fas fa-play-circle"></i>
            </button>
            <button
              type="button"
              class="
                flex-1
                mx-2
                bg-red-400
                ring ring-red-300
                rounded-full
                text-lg
                shadow
                text-white
                hover:bg-red-500
              "
              @click="stopTimer"
              v-else
            >
              <i class="fas fa-stop-circle"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppTimer",
  props: {
    name: String,
    role: Object,
  },
  data: () => ({
    elapsedTime: 0,
    timer: undefined,
    timerOn: false,
    // timerBgClass: "flex-1 px-2 text-white ring ring-gray-200 rounded text-center shadow bg-gray-600 text-lg font-mono"
  }),
  computed: {
    timerBgClass() {
      if (
        this.elapsedTime >= this.role.timer_alert_setting.g &&
        this.elapsedTime < this.role.timer_alert_setting.a
      ) {
        return "flex-1 px-2 text-white ring ring-gray-200 rounded text-center shadow bg-green-500 text-lg font-mono";
      } else if (
        this.elapsedTime >= this.role.timer_alert_setting.a &&
        this.elapsedTime < this.role.timer_alert_setting.r
      ) {
        return "flex-1 px-2 text-white ring ring-gray-200 rounded text-center shadow bg-yellow-500 text-lg font-mono";
      } else if (this.elapsedTime >= this.role.timer_alert_setting.r) {
        return "flex-1 px-2 text-white ring ring-gray-200 rounded text-center shadow bg-red-500 text-lg font-mono";
      } else {
        return "flex-1 px-2 text-white ring ring-gray-200 rounded text-center shadow bg-gray-600 text-lg font-mono";
      }
    },
    formattedElapsedTime() {
      const date = new Date(null);
      date.setSeconds(this.elapsedTime / 1000);
      const utc = date.toUTCString();
      return utc.substr(utc.indexOf(":") - 2, 8);
    },
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.elapsedTime += 1000;
      }, 1000);
      this.timerOn = true;
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timerOn = false;
    },
    resetTimer() {
      this.elapsedTime = 0;
    },
  },
});
</script>