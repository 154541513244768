<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="open"
      :open="open"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block
          sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8
              sm:align-middle
              sm:max-w-lg
              sm:w-full
            "
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="
                    mx-auto
                    flex-shrink-0 flex
                    items-center
                    justify-center
                    h-12
                    w-12
                    rounded-full
                    bg-red-100
                    sm:mx-0
                    sm:h-10
                    sm:w-10
                  "
                >
                  <TranslateIcon
                    class="h-6 w-6 text-purple-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-gray-900"
                  >
                    {{ subject }}
                  </DialogTitle>
                  <div class="mt-2">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Type in your word..."
                      class="
                        mt-1
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        block
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                      v-model="word"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
            >
              <button
                type="button"
                class="
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-purple-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-red-700
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                  sm:ml-3
                  sm:w-auto
                  sm:text-sm
                "
                @click="enterWorld"
              >
                Enter
              </button>
              <button
                type="button"
                class="
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-purple-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-red-700
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                  sm:ml-3
                  sm:w-auto
                  sm:text-sm
                "
                @click="enterIFeelLucky"
              >
                I'm feeling Lucky
              </button>
              <span v-if="hasWarning" class="warning-class">
                <p style="color: red">
                  <strong>Warning:</strong> must enter a valid word!
                </p>
                <br />
              </span>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
//eslint-disable-next-line
import { toRefs } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { TranslateIcon } from "@heroicons/vue/outline";
import axios from "axios";
export default {
  name: "GrammarianInitializerModal",

  props: {
    subject: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
    invalidWord: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    TranslateIcon,
  },
  setup(props, context) {
    let parentProps = toRefs(props);
    // get all parent properties
    let isOpen = parentProps.isOpen.value;
    let open = isOpen;
    let word = "";
    let hasWarning = false;
    let wordDefinition = "";

    async function enterWorld() {
      // emitting closing event from child to parent

      if (this.word.length > 1) {
        // definte the word
        let definitionRes = await defineWord(this.word);
        if (definitionRes.status !== 200) {
          this.hasWarning = true;
          context.emit("enter-grammarian-word", this.hasWarning);
        } else {
          let parentProps = toRefs(props);
          this.open = parentProps.isOpen.value;
          console.log("emitting definitionRes data", definitionRes.data[0]);
          context.emit("enter-grammarian-word", definitionRes.data[0]);
        }
      } else {
        if (!this.hasWarning) {
          this.hasWarning = true;
          context.emit("enter-grammarian-word", this.hasWarning);
        }
      }
    }

    async function getRandomWord() {
      const randomWordEndpoint =
        "https://random-word-api.herokuapp.com/word?swear=1&&number=1";

      try {
        const response = await axios.get(randomWordEndpoint);
        return response.data[0];
      } catch (err) {
        if (err.response) {
          console.log("Server error found: ", err);
        } else if (err.request) {
          console.log("Network Error: ", err);
        } else {
          console.log("Client Error: ", err);
        }
      }
    }

    async function enterIFeelLucky() {
      let randomWord = await getRandomWord();

      let definitionRes = await defineWord(randomWord);

      while (definitionRes.status !== 200) {
        randomWord = await getRandomWord();
        definitionRes = await defineWord(randomWord);
      }
      this.wordDefinition = definitionRes.data;
      console.log("word definition: ", this.wordDefinition);
      if (randomWord.length > 1) {
        let parentProps = toRefs(props);
        this.open = parentProps.isOpen.value;
        context.emit("enter-grammarian-word", this.wordDefinition[0]);
      } else {
        if (!this.hasWarning) {
          this.hasWarning = true;
          context.emit("enter-grammarian-word", this.hasWarning);
        }
      }
    }

    async function defineWord(w) {
      /**
       * given a word, call the word definition api endpiont to get its definition
       */
      let wordDefinitionAPIEndpoint =
        "https://api.dictionaryapi.dev/api/v2/entries/en_US/";

      // lowercase and remove trailing whitespaces
      let lowerCaseW = w.toLowerCase();

      let strippedW = lowerCaseW.trim();
      let defURL = wordDefinitionAPIEndpoint.concat(strippedW);
      console.log("definition url: ", defURL);
      // call wordDefinitionEndpoint
      const response = await axios.get(defURL).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        return error;
      });

      console.log("Definition response status code ", response.status);
      console.log("Definition response data ", response.data);
      return response;
    }

    return {
      enterWorld,
      open,
      word,
      hasWarning,
      getRandomWord,
      defineWord,
      enterIFeelLucky,
      wordDefinition,
    };
  },
};
</script>

<style scoped>
.warning-class {
  position: absolute;
  left: 0;
  padding: 8px 8px;
  opacity: 0.8;
}
</style>