// captions static file store descriptions for different views/pages of the app

export const captions = {
	// endpoints
	"/": {
		p1: "We support the skill- and community-building power of Toastmasters while moving some of the traditional meeting tools into the digital age.",
		p2: "Toastmasters International is a nearly century-old organization focused on promoting communication, public speaking, and leadership. While Toastmasters has maintained its roots in these important skills, the meetings have moved into the 21st century by taking into account our virtual collaboration.",
		p3: "Toastmasters meetings have functional roles to record and share feedback. Traditionally, this has been through paper, stopwatches, and verbal feedback.",
		p4: "Our apps allow meeting functionaries to perform their duties and report their findings while also aggregating the data for participants to understand trends as they progress in their Toastmasters journey.",
		p5: "Are you a Toastmasters meeting functionary? Use our app for your role to track time, filler words, and your members' involvement.",
	},

	"/apps": {
		description:
			"We believe that data help us tell stories, and what we can measure, we can change. That’s why Toasty offers apps to help meeting functionaries track member performance and report on it for meetings. Each app allows you to create a list based on role and name and then view a dashboard of your meeting’s participants",
		timer: "Track how much time each person takes for their role",
		ah_counter: "Track filler words for each speaker",
		grammarian:
			"Introduce a word of the day, track its usage, and mark any interesting turns of phrase or language issues",
		table_topics: "Create a list of topics for impromptu speeches",
	},
	"/apps/timer": {
		description:
			"Concision and using time well in meetings are important Toastmasters skills! The timer plays a key role: Track and report on each speaker’s time at the mic. You’ll help meeting participants stay on track with this timer app, which allows you to track multiple speakers, customize your list, and see when a speaker is over time for each speech type. For a full script for the Timer role, click on the speech bubble icon in the top-right corner",
	},
	"/apps/ah-counter": {
		description:
			"Speaking with fluidity and without a lot of filler words are important Toastmasters skills! The Ah Counter plays a key role: Keep track of and report on each speaker’s filler words. You’ll help meeting participants be more mindful of their filler words with this timer app, which allows you to track multiple speakers, customize your list, and report on the verbal crutches speakers use. For a full script for the Ah Counter role, click on the speech bubble icon in the top-right corner",
	},
	"/apps/grammarian": {
		description:
			"Building vocabulary and using interesting turns of phrase are important Toastmasters skills! The Grammarian helps with both: Keep track of and report on each speaker’s use of the featured word. You can choose your own word or let the app generate one for you (complete with definition, pronunciation, and usage examples). Track how often each speaker uses the word of the day and report at the end of the meeting. For a full script for the Grammarian role, click on the speech bubble icon in the top-right corner",
	},
	"/apps/table-topics": {
		description:
			"Being able to respond to a topic in the moment wand making compelling points succinctly are important Toastmasters skills! The Topics Master plays a key role: Propose though-provoking topics for meeting attendees to respond to in impromptu speeches. You’ll help meeting participants practice thinking on their feet with 1–2 minute speeches. Add your table topics subjects to the tool to create doors that flip open to reveal the speakers’ prompts. For a full script for the Topics Master role, click on the speech bubble icon in the top-right corner",
	},
};
