<template>
  <div id="counter-view">
    <nav>
      <router-link to="/">
        <div class="navigation__logo">Home</div>
      </router-link>

      <div class="navigation__user">
        {{ state.counterName }}
      </div>
    </nav>
    <!--display counter roles-->
    <div class="counter-view__counter-roles">
      <div class="counter-view__counter-role" 
           v-for="(role, index) in state.roles.participants" 
           :key="index" @click="onClickSelect(index)" 
           v-bind:class="{'isRoleSelected': role.isSelected}">
           <i class="far fa-smile" v-bind:class="{'fas fa-sad-cry': role.isSelected}"/>
        {{ role.name }}
      </div>
    </div>
    <!--display card below-->
    <div class="counter-view__counter-card" v-if="state.roles.currentRoleIndex != -1">
      <CounterCard 
        v-for="(fillerWord, index) in state.roles.participants[state.roles.currentRoleIndex].fillerWords"
        :key="index"
        :word="fillerWord.word"
        :wordCount=fillerWord.wordCount
        v-on:increment-count="increment(index)"
        v-on:decrement-count="decrement(index)"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
//import {fillerWords} from "@/assets/fillerWords"    // replace this with store in vuex
import CounterCard from "@/components/CounterCard"

export default {
  name: "CounterView",
  components: { CounterCard },

  setup() {
    const store = useStore();
    const state = reactive({
      counterName: "Alfred44",
      fillerWords: computed(() => store.state.Counter.fillerWords),
      roles: computed(() => store.state.Roles.roles)
    });

    function increment(index) {
      //store.dispatch('Counter/increaseFillerWords',index)
      store.dispatch('Roles/increaseFillerWords', index)
    }

    function decrement(index) {
      //store.dispatch('Counter/decreaseFillerWords',index)
      store.dispatch('Roles/decreaseFillerWords',index)
    }

    function onClickSelect(index) {
      store.dispatch('Roles/setSelectedRole', index)
    }

    return {
      state,
      increment,
      decrement,
      onClickSelect
    }
  }

}
</script>

<style lang="scss" scoped>
#counter-view {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  background-color: #f3f5fa;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5%;
    background-color: #1dcaff;
    color: white;
    height: 86px;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;

    .navigation__logo {
      font-weight: bold;
      font-size: 24px;
      color: white;
    }

    .navigation__user {
      font-family: Chalkduster, fantasy;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.counter-view__counter-card {
  display: grid;
  margin-top: 25px;
  margin-left: 20px;
  margin-right: 20px;
  grid-gap: 20px;
  margin-bottom: auto;
}

.counter-view__counter-roles {
    display: grid;
    margin-top: 25px;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 20px;
    margin-right: 20px;
    grid-gap: 1rem;
  }
  .counter-view__counter-role{
    border: 1px solid #ccc;
    background: #ffc6c4;
    color: black;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
    position: relative;
    font-weight: bold;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    -moz-box-shadow:    3px 3px 5px 6px #ccc;
    box-shadow:         3px 3px 5px 6px #ccc; 
    cursor: pointer;
  }

  .isRoleSelected {
    background: #ff726f;
    color: white;
    -moz-box-shadow:    inset 0 0 5px #000000;
    -webkit-box-shadow: inset 0 0 5px #000000;
    box-shadow:         inset 0 0 5px #000000;
  }

  @media (max-width: 500px) {
    .counter-view__counter-roles {
      grid-template-columns: 1fr;
    }
  }
</style>