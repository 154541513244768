<template>
    <!-- main class="container mt-5 mx-auto flex flex-wrap relative" -->
    <main class="container px-10 mt-6 mx-auto">
      <div class="absolute">
        <router-link
          to="/apps"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
        </router-link>
      </div>
      <slot name="app-body" />
  </main>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseLayoutApp',
  components: {
  },
});
</script>
