export const Timer = {
  namespaced: true,

  // state: {
  //   users: [],
  // },

  // mutations: {
  //   ADD_USER (state, data) {
  //     console.log('add user')
  //   }
  // },

  // getters: {
  //   getUsers (state) {
  //     return state.users
  //   }
  // }
}
