export const RoleMap = {
	namespaced: true,

	state: {
		roles: null,
	},

	mutations: {
		SET_ROLEMAPS(state, roleMaps) {
			state.roles = roleMaps;
		},
	},
	getters: {
		getRolebyName: (state) => (name) => {
			return state.roles[name];
		},
		getRoles(state) {
			return state.roles;
		},
	},
	actions: {
		setRoles({ commit }, roleMaps) {
			commit("SET_ROLEMAPS", roleMaps);
		},
	},
};
