import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

import AppGrammarian from "@/views/apps/AppsGrammarian.vue";
//import AppRandomBook from "@/components/apps/AppRandomBook.vue";
import AppsTimer from "@/views/apps/AppsTimer.vue";
import AppsAhCounter from "@/views/apps/AppsAhCounter.vue";
import CounterView from "@/views/CounterView.vue"; // NOT USED. leave it here for now as an example
import TableTopics from "@/views/apps/AppsTableTopics.vue";

// use static fillerWords as default value if fillerWords are not fetched by future APIs
import { fillerWords } from "@/assets/fillerWords";
import { roles } from "@/assets/roles";
import { functionaryPrompts } from "@/assets/functionaryPrompts";
import { captions } from "@/assets/captions";

import axios from "axios";

// import roleMaps static data here
//import { roleMappings } from "@/assets/roleMaps";
const roleMappingEndpoint =
	"https://hnx9ujwzqb.execute-api.us-east-1.amazonaws.com/default/dynamoDBHandler";
const routes = [
	{
		path: "/auth",
		name: "auth",
		component: () => import("@/views/SignIn.vue"),
	},
	{
		path: "/",
		name: "home",
		component: () => import("@/views/Home.vue"),
	},
	{
		path: "/apps",
		name: "apps",
		component: () => import("@/views/Apps.vue"),
	},
	{
		path: "/members",
		name: "members",
		component: () => import("@/views/Members.vue"),
		// meta: { requiresAuth: true }
	},
	{
		path: "/apps/timer",
		name: "app-timer",
		component: AppsTimer,
	},
	{
		path: "/apps/table-topics",
		name: "table-topics",
		component: TableTopics,
	},
	{
		path: "/apps/grammarian",
		name: "app-grammarian",
		component: AppGrammarian,
	},
	{
		// ah counter page view
		path: "/apps/counter",
		name: "counter",
		component: CounterView,
	},
	{
		// new path for flex counter view
		path: "/apps/ah-counter",
		name: "ah-counter",
		component: AppsAhCounter,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	linkActiveClass: "active",
	linkExactActiveClass: "active",
});

let config = {
	headers: {
		"x-api-key": "4NxeRYEcvr9Vsw1sjIHV8aVJKocTWOy11trYnZzn",
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "application/json",
	},
};

router.beforeEach((to, from, next) => {
	/* 
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const loggedIn = store.getters['Auth/isLoggedIn']
    if (loggedIn) {
      next()
      return
    }
    next({ name: 'auth'})
  } else { 
    // populate fillerWords if not already exist
    if(!store.state.Counter.fillerWords) {
      // use store.dispatch to call the actions
      store.dispatch('Counter/setFillerWords', fillerWords)
    }

    if(!store.state.Roles.roles) {
      // replace this will api call in the future
      store.dispatch('Roles/setRoles', roles)
    }
    next() 
  }
  */
	if (!store.state.Counter.fillerWords) {
		// use store.dispatch to call the actions
		store.dispatch("Counter/setFillerWords", fillerWords);
	}
	if (!store.state.Roles.roles) {
		// replace this will api call in the future
		store.dispatch("Roles/setRoles", roles);
	}
	if (!store.state.Functionary.prompts) {
		// set functionary prompts from static file
		store.dispatch("Functionary/setPrompts", functionaryPrompts);
	}
	if (!store.state.Users.users) {
		store.state.Users.users = {};
	}
	if (!store.state.Captions.caption) {
		store.dispatch("Captions/setCaptions", captions);
	}
	if (!store.state.RoleMap.roles) {
		// this one used by the timer app
		(async () => {
			try {
				const response = await axios.get(roleMappingEndpoint, config);
				store.dispatch("RoleMap/setRoles", response.data);
			} catch (err) {
				console.log("found error");
				if (err.response) {
					console.log("Server error found: ", err);
				} else if (err.request) {
					console.log("Network Error: ", err);
				} else {
					console.log("Client Error: ", err);
				}
			}
		})();
	}
	next();
});

export default router;
