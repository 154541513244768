export const Roles = {
	namespaced: true, // this allows you to reference this module by its name Counter

	state: {
		roles: null,
	},

	mutations: {
		SET_ROLES(state, roles) {
			state.roles = roles;
		},

		SET_SELECTED_ROLE(state, index) {
			// check if the current currentRoleIndex is -1
			if (state.roles["currentRoleIndex"] === -1) {
				// if -1, meaning this is the first time the role is selected
				// update currentRoleIndex to the index value
				state.roles["currentRoleIndex"] = index;
				state.roles["participants"][index]["isSelected"] = true;
			} else {
				const oldRoleIndex = state.roles["currentRoleIndex"];
				if (oldRoleIndex !== index) {
					// set the old isSelected back to false
					state.roles["participants"][oldRoleIndex]["isSelected"] = false;

					// update eth currentRoleIndex to index
					state.roles["currentRoleIndex"] = index;

					// set the correspopnding participant isSelected to true
					state.roles["participants"][index]["isSelected"] = true;
				}
			}
		},

		INCREASE_FILLERWORDS(state, payload) {
			/*if (state.roles.currentRoleIndex !== -1) {
				const selectedRoleIndex = state.roles.currentRoleIndex;
				state.roles["participants"][selectedRoleIndex]["fillerWords"][index][
					"wordCount"
				] += 1;
				console.log("the index is....", index);
				console.log("selected role index: ", selectedRoleIndex);
			}*/
			// sindex is the slider index
			// cindex is the selected counter card index (increase / descrease)
			let participant_index = payload["sindex"];
			let card_index = payload["cindex"];
			state.roles["participants"][participant_index]["fillerWords"][card_index][
				"wordCount"
			] += 1;
		},

		DECREASE_FILLERWORDS(state, payload) {
			/*if (state.roles.currentRoleIndex !== -1) {
				const selectedRoleIndex = state.roles.currentRoleIndex;
				if (
					state.roles["participants"][selectedRoleIndex]["fillerWords"][index][
						"wordCount"
					] > 0
				) {
					state.roles["participants"][selectedRoleIndex]["fillerWords"][index][
						"wordCount"
					] -= 1;
				}
			}*/
			let participant_index = payload["sindex"];
			let card_index = payload["cindex"];
			if (
				state.roles["participants"][participant_index]["fillerWords"][
					card_index
				]["wordCount"] > 0
			) {
				state.roles["participants"][participant_index]["fillerWords"][
					card_index
				]["wordCount"] -= 1;
			}
		},
	},

	actions: {
		setRoles({ commit }, roles) {
			commit("SET_ROLES", roles);
		},

		setSelectedRole({ commit }, roles, index) {
			commit("SET_SELECTED_ROLE", roles, index);
		},

		increaseFillerWords({ commit }, roles, payload) {
			commit("INCREASE_FILLERWORDS", roles, payload);
		},

		decreaseFillerWords({ commit }, roles, payload) {
			commit("DECREASE_FILLERWORDS", roles, payload);
		},
	},
};
