import { v4 as uuidv4 } from "uuid";

export const Users = {
	namespaced: true,

	state: {
		users: [],
	},

	mutations: {
		CREATE_USER(state, params) {
			const id = uuidv4();
			let newUser = Object.assign(params, { id: id });
			state.users.push(newUser);
		},

		STACK_USER(state, params) {
			const id = uuidv4();
			let newUser = Object.assign(params, { id: id });
			state.users.unshift(newUser);
		},
		DELETE_USER(state, id) {
			state.users = state.users.filter(function (obj) {
				return obj.id !== id;
			});
		},

		DECREASE_FILLERWORDS(state, payload) {
			let userId = payload["userId"];
			let wordIndex = payload["wordIndex"];

			// find the user index by id
			let userIndex = state.users.findIndex((obj) => obj.id == userId);

			// only handle subtraction if the exist count is greater than 0
			if (state.users[userIndex].fillerWords[wordIndex]["wordCount"] > 0) {
				state.users[userIndex].fillerWords[wordIndex]["wordCount"] -= 1;
			}
		},

		INCREASE_FILLERWORDS(state, payload) {
			let userId = payload["userId"];
			let wordIndex = payload["wordIndex"];

			// find the user index by id
			let userIndex = state.users.findIndex((obj) => obj.id == userId);

			// increment filler wordCount for that user
			state.users[userIndex].fillerWords[wordIndex]["wordCount"] += 1;
		},

		INCREASE_GRAMMARIAN_WORDS(state, userId) {
			let userIndex = state.users.findIndex((obj) => obj.id == userId);
			state.users[userIndex]["grammarianWord"] += 1;
		},

		DECREASE_GRAMMARIAN_WORDS(state, userId) {
			let userIndex = state.users.findIndex((obj) => obj.id == userId);
			if (state.users[userIndex]["grammarianWord"] > 0) {
				state.users[userIndex]["grammarianWord"] -= 1;
			}
		},
	},

	getters: {
		getUsers(state) {
			return state.users;
		},
	},

	actions: {
		addUser({ commit }, user) {
			commit("CREATE_USER", user);
		},

		deleteUser({ commit }, userId) {
			commit("DELETE_USER", userId);
		},

		stackUser({ commit }, user) {
			commit("STACK_USER", user);
		},

		increaseFillerWords({ commit }, roles, payload) {
			commit("INCREASE_FILLERWORDS", roles, payload);
		},

		decreaseFillerWords({ commit }, roles, payload) {
			commit("DECREASE_FILLERWORDS", roles, payload);
		},

		increaseGrammarianWords({ commit }, roles, userId) {
			commit("INCREASE_GRAMMARIAN_WORDS", roles, userId);
		},

		decreaseGrammarianWords({ commit }, roles, userId) {
			commit("DECREASE_GRAMMARIAN_WORDS", roles, userId);
		},
	},
};
