export const Auth = {
  namespaced: true,

  state: {
    loggedIn: false,
    username: null,
  },

  mutations: {
    updateLogin (state, data) {
      state.loggedIn = data.loggedIn
      state.username = data.username
    }    
  },

  getters: {
    isLoggedIn (state) {
      return state.loggedIn
    }    
  }
}
