<template>
  <base-layout-app>
    <template v-slot:app-body>
      <functionary-prompt-modal
        v-if="state.hasPromptOpen"
        :role="state.functionary"
        :prompt="state.timerPrompt"
        :isOpen="state.hasPromptOpen"
        v-on:close-prompt-modal="closeFunctionaryPrompt"
      />

      <AnnotationIcon
        class="h-6 w-6 text-purple-600 absolute top-0 right-0 h-16 w-16"
        style="cursor: pointer"
        aria-hidden="true"
        @click="openFunctionaryPrompt"
      />
      <section class="text-gray-600 body-font">
        <div
          class="
            container
            px-5
            py-10
            mx-auto
            border
            rounded-2xl
            shadow
            bg-white
          "
        >
          <div class="flex flex-col text-center w-full mb-10">
            <h1
              class="
                sm:text-3xl
                text-2xl
                font-medium
                title-font
                mb-4
                text-purple-700
              "
            >
              Timer App
            </h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
              {{ caps["/apps/timer"]["description"] }}
            </p>
          </div>
          <div class="overflow-hidden sm:rounded-md">
            <div class="px-4 py-4 bg-white sm:p-4">
              <button
                class="
                  w-full
                  mt-2
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-indigo-400
                  hover:bg-indigo-500
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
                @click="editTimeSettings = !editTimeSettings"
              >
                Edit time settings
              </button>
            </div>
          </div>
          <div class="overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    for="name"
                    class="block text-sm font-medium text-gray-700"
                    >Name</label
                  >
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autocomplete="full-name"
                    class="
                      mt-1
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      shadow-sm
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                    v-model="name"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    for="role"
                    class="block text-sm font-medium text-gray-700"
                    >Role</label
                  >
                  <select
                    id="role"
                    name="role"
                    autocomplete="role"
                    class="
                      mt-1
                      block
                      w-full
                      py-2
                      px-3
                      border border-gray-300
                      bg-white
                      rounded-md
                      shadow-sm
                      focus:outline-none
                      focus:ring-indigo-500
                      focus:border-indigo-500
                      sm:text-sm
                    "
                    v-model="role"
                  >
                    <option v-for="role in roles" :key="role.id" :value="role">
                      {{ role.data.role }}
                    </option>
                  </select>
                </div>
                <div
                  class="
                    col-span-6
                    sm:col-span-3
                    md:col-span-6
                    lg:col-span-2
                    pt-5
                  "
                >
                  <button
                    class="
                      mt-1
                      block
                      w-full
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-indigo-600
                      hover:bg-indigo-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    @click="addUser"
                  >
                    Add user
                  </button>
                </div>
              </div>
            </div>
            <!-- div class="input-group py-4 mx-auto">
              <input type="text" class="form-control" v-model="name" placeholder="Name" required>
              <input type="text" class="form-control" v-model="name" placeholder="Name" required>
              <button class="btn btn-success" @click="addUser">Add user</button>
            </div -->
          </div>
          <div v-if="state.users.length !== 0" class="flex flex-wrap">
            <app-timer
              v-for="(user, index) in state.users"
              :key="index"
              :name="user.name"
              :role="user.role"
              @del-user="delUser(user.id)"
            />
          </div>
        </div>
      </section>
      <!-- edit modal -->
      <div
        v-if="editTimeSettings"
        class="
          min-w-screen
          h-screen
          animated
          fadeIn
          faster
          fixed
          left-0
          top-0
          flex
          justify-center
          items-center
          inset-0
          z-50
          outline-none
          focus:outline-none
        "
      >
        <div
          class="
            flex flex-col
            p-8
            bg-white
            shadow-lg
            hover:shodow-xl
            rounded-2xl
            border-2 border-gray-100
          "
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <div class="flex flex-col ml-3">
                <div class="font-medium leading-none">Coming soon!</div>
                <p class="text-sm text-gray-600 leading-none mt-1">
                  This feature will allow you to modify time thresholds
                </p>
              </div>
            </div>
            <button
              class="
                flex-no-shrink
                bg-indigo-500
                px-5
                ml-4
                py-2
                text-sm
                shadow-sm
                hover:shadow-lg
                font-medium
                tracking-wider
                border-2 border-indigo-500
                text-white
                rounded-full
              "
              @click="editTimeSettings = !editTimeSettings"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <button
        v-if="state.users.length > 0"
        class="
          w-full
          mt-2
          justify-center
          py-2
          px-4
          border border-transparent
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-white
          bg-indigo-400
          hover:bg-indigo-500
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
        "
        @click="downloadCSV"
      >
        Download Report
      </button>
    </template>
  </base-layout-app>
</template>

<script>
import { defineComponent, reactive, computed } from "vue";
import BaseLayoutApp from "@/layouts/BaseLayoutApp.vue";
import { useStore } from "vuex";
import AppTimer from "@/components/apps/AppTimer.vue";
import FunctionaryPromptModal from "@/components/apps/FunctionaryPromptModal.vue";
import { AnnotationIcon } from "@heroicons/vue/solid";
import { Parser } from "json2csv";

export default defineComponent({
  name: "AppsTimer",

  components: {
    BaseLayoutApp,
    AppTimer,
    FunctionaryPromptModal,
    AnnotationIcon,
  },
  setup() {
    const store = useStore();
    const roles = computed(() => store.state.RoleMap.roles);
    const caps = store.state.Captions.captions;
    const state = reactive({
      editTimeSettings: false,
      timerPrompt: computed(() => store.state.Functionary.prompts["timer"]),
      name: "",
      role: {},
      users: computed(() => store.state.Users.users),
      hasPromptOpen: false,
      functionary: "Timer",
    });

    function addUser() {
      let roleObj = JSON.parse(JSON.stringify(this.role));
      const user = { name: this.name, role: roleObj["data"] };
      store.dispatch("Users/addUser", user);
      this.name = "";
      this.role = {};
    }

    function delUser(id) {
      //store.state.Users.deleteUser(id);
      store.dispatch("Users/deleteUser", id);
    }

    function downloadCSV() {
      const json2csvParser = new Parser();
      const csv = json2csvParser.parse(state.users);
      console.log(csv);
    }
    function openFunctionaryPrompt() {
      state.hasPromptOpen = true;
    }

    function closeFunctionaryPrompt() {
      state.hasPromptOpen = false;
    }

    return {
      roles,
      caps,
      state,
      addUser,
      delUser,
      downloadCSV,
      openFunctionaryPrompt,
      closeFunctionaryPrompt,
    };
  },
});
</script>

<style scoped>
main {
  font-family: "Poppins", sans-serif;
}
</style>