<template>
  <div class="col-span-3">
    <hr class="mt-3 mb-3" />
    <div class="flex">
      <button
        type="button"
        class="
          flex-1
          mx-2
          transition
          duration-300
          bg-red-300
          ring ring-red-300
          rounded-full
          text-lg
          shadow-lg
          text-white
          hover:bg-red-400
        "
        @click="onClickDecrease"
      >
        <i class="fas fa-minus"></i>
      </button>

      <div
        class="
          lg:pr-10 lg:col-span-1
          p-5
          bg-grey-600
          text-black text-2xl text-center
          border-none
          my-4
          text-lg
        "
      >
        {{ word }}: {{ wordCount }}
      </div>

      <button
        type="button"
        class="
          flex-1
          mx-2
          bg-green-500
          ring ring-green-300
          rounded-full
          text-lg
          shadow
          text-white
          hover:bg-green-600
        "
        @click="onClickIncrease"
      >
        +
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CounterCardFlex",
  props: {
    word: {
      type: String,
      required: true,
    },
    wordCount: {
      type: Number,
      required: true,
    },
  },

  setup(props, context) {
    function onClickIncrease() {
      context.emit("increment-fillerword-count", "increment");
    }

    function onClickDecrease() {
      context.emit("decrement-fillerword-count", "decrement");
    }

    return {
      onClickIncrease,
      onClickDecrease,
    };
  },
};
</script>

