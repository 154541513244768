<template>
  <base-layout-app>
    <template v-slot:app-body>
      <functionary-prompt-modal
        v-if="state.hasPromptOpen"
        :role="state.functionary"
        :prompt="state.ahCounterPrompt"
        :isOpen="state.hasPromptOpen"
        v-on:close-prompt-modal="closeFunctionaryPrompt"
      />
      <AnnotationIcon
        class="h-6 w-6 text-purple-600 absolute top-5 right-0 h-16 w-16"
        style="cursor: pointer"
        aria-hidden="true"
        @click="openFunctionaryPrompt"
      />

      <DocumentReportIcon
        class="h-6 w-6 text-indigo-600 absolute top-20 right-0 h-16 w-16"
        style="cursor: pointer"
        aria-hidden="true"
        v-if="state.users.length !== 0"
        @click="openAhCounterReportModal"
      />

      <ah-counter-tally-modal
        v-if="state.hasAhTallyModalOpen && state.tallyUserIndex >= 0"
        :isTallyBoardOpen="state.hasAhTallyModalOpen"
        :participant="state.users[state.tallyUserIndex]"
        v-on:close-ah-tally-modal="closeTallyModal"
        v-on:tally-ah-increment="increaseFillerwordTally"
        v-on:tally-ah-decrement="decreaseFillerwordTally"
      />

      <main class="container px-10 mt-5 mx-auto sm:max-w-lg md:max-w-full">
        <section class="text-gray-600 body-font">
          <div
            class="
              container
              px-5
              py-8
              mx-auto
              border
              rounded-2xl
              shadow
              bg-white
            "
          >
            <div class="flex flex-col text-center w-full mb-10">
              <h1
                class="
                  sm:text-3xl
                  text-2xl
                  font-medium
                  title-font
                  mb-4
                  text-purple-700
                "
              >
                Ah Counter
              </h1>
              <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
                {{ caps["/apps/ah-counter"]["description"] }}
              </p>
            </div>
            <div class="overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      for="name"
                      class="block text-sm font-medium text-gray-700"
                      >Name</label
                    >
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autocomplete="full-name"
                      class="
                        mt-1
                        focus:ring-indigo-500 focus:border-indigo-500
                        block
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                      v-model="name"
                    />
                  </div>
                  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      for="role"
                      class="block text-sm font-medium text-gray-700"
                      >Role</label
                    >
                    <select
                      id="role"
                      name="role"
                      autocomplete="role"
                      class="
                        mt-1
                        block
                        w-full
                        py-2
                        px-3
                        border border-gray-300
                        bg-white
                        rounded-md
                        shadow-sm
                        focus:outline-none
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        sm:text-sm
                      "
                      v-model="role"
                    >
                      <option
                        v-for="role in roles"
                        :key="role.id"
                        :value="role"
                      >
                        {{ role.data.role }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="
                      col-span-6
                      sm:col-span-3
                      md:col-span-6
                      lg:col-span-2
                      pt-5
                    "
                  >
                    <button
                      class="
                        mt-1
                        block
                        w-full
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-indigo-600
                        hover:bg-indigo-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      input="disable"
                      @click="addUser"
                    >
                      Add Participant
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!--ahCounter card here-->
            <div
              v-if="
                state.users.length !== 0 && !state.hasAhCounterReportModalOpen
              "
              class="flex flex-wrap"
            >
              <ah-counter-card
                v-for="(user, index) in state.users"
                :key="index"
                :name="user.name"
                :role="user.role"
                :id="index"
                @del-ah-counter-user="delUser(user.id)"
                @openCounterTallyModalEvent="handleOpenCounterTallyModalEvent"
              />
            </div>

            <!--carousel-->
            <div
              class="flex flex-col justify-center items-center"
              v-if="
                state.hasAhCounterReportModalOpen && state.slides.length !== 0
              "
            >
              <vue-carousel
                interval="50000"
                class="w-3/4"
                :data="state.slides"
                indicators="hover"
              ></vue-carousel>
            </div>
          </div>
        </section>
      </main>
    </template>
  </base-layout-app>
</template>

<script>
import { defineComponent, reactive, computed } from "vue";
import { useStore } from "vuex";
import BaseLayoutApp from "@/layouts/BaseLayoutApp.vue";
import { AnnotationIcon } from "@heroicons/vue/solid";
import { DocumentReportIcon } from "@heroicons/vue/solid";
import FunctionaryPromptModal from "@/components/apps/FunctionaryPromptModal.vue";
import AhCounterCard from "@/components/apps/AhCounterCard";
import AhCounterTallyModal from "@/components/apps/AhCounterTallyModal.vue";
import VueCarousel from "@chenfengyuan/vue-carousel";

export default defineComponent({
  name: "AppAhCounter",
  components: {
    BaseLayoutApp,

    AnnotationIcon,
    DocumentReportIcon,

    // custome components for ah counter app
    AhCounterCard,
    AhCounterTallyModal,
    FunctionaryPromptModal,
    VueCarousel,
  },
  setup() {
    const store = useStore();
    const caps = store.state.Captions.captions;
    const roles = computed(() => store.state.RoleMap.roles);
    const fillerWords = computed(() => store.state.Counter.fillerWords);
    const roleImageMap = {
      Toastmaster: "toastmaster.svg",
      "Joke Master": "jokeMaster.png",
      Speaker: "speaker1.svg",
      TopicMaster: "topicMaster.png",
      "Table Topic Responder": "responses.png",
      "General Evaluator": "generalEvaluator.png",
      Timer: "timer.svg",
    };

    const state = reactive({
      hasPromptOpen: false,
      hasAhTallyModalOpen: false,
      hasAhCounterReportModalOpen: false,
      funcionary: "Ah Counter",
      tallyUserIndex: -1,
      role: {},
      ahCounterPrompt: computed(
        () => store.state.Functionary.prompts["ahCounter"]
      ),
      users: computed(() => store.state.Users.users),
      //:src="require(`@/assets/roles/${thisParticipant.rolePic}`)"
      slides: [],
    });

    function openFunctionaryPrompt() {
      state.hasPromptOpen = true;
    }

    function closeFunctionaryPrompt() {
      state.hasPromptOpen = false;
    }

    function addUser() {
      let roleObj = JSON.parse(JSON.stringify(this.role));
      let fillerWords = JSON.parse(JSON.stringify(this.fillerWords));
      let user;
      // check if the role should included
      if (roleObj["data"]["role"] in roleImageMap) {
        user = {
          name: this.name.trim(),
          role: roleObj["data"],
          fillerWords: fillerWords,
          rolePic: roleImageMap[roleObj["data"]["role"]],
        };
      } else {
        user = {
          name: this.name.trim(),
          role: roleObj["data"],
          fillerWords: fillerWords,
          rolePic: "others.svg",
        };
      }
      store.dispatch("Users/addUser", user);
      this.name = "";
      this.role = {};
    }

    function handleOpenCounterTallyModalEvent(index) {
      state.hasAhTallyModalOpen = true;
      state.tallyUserIndex = index;
      state.hasAhCounterReportModalOpen = false;
    }

    function closeTallyModal() {
      state.hasAhTallyModalOpen = false;
      state.tallyUserIndex = -1;
    }

    function decreaseFillerwordTally(payload) {
      store.dispatch("Users/decreaseFillerWords", payload);
    }

    function increaseFillerwordTally(payload) {
      store.dispatch("Users/increaseFillerWords", payload);
    }

    function delUser(id) {
      store.dispatch("Users/deleteUser", id);
    }

    function openAhCounterReportModal() {
      state.hasAhCounterReportModalOpen = !state.hasAhCounterReportModalOpen;
      state.hasAhTallyModalOpen = false;

      if (state.hasAhCounterReportModalOpen) {
        state.slides = [];
        for (let i = 0; i < state.users.length; i++) {
          let name = state.users[i].name;
          let role = state.users[i].role.role;
          let fillerWords = state.users[i].fillerWords;
          let roleReportCard = _buildSlideElement(name, role, fillerWords);
          state.slides.push(roleReportCard);
        }
      }
    }

    function _buildSlideElement(name, role, fillerWords) {
      let fillerwordsReportComponent = "<ul>";
      for (let i = 0; i < fillerWords.length; i++) {
        fillerwordsReportComponent += `<li>${fillerWords[i].word}: ${fillerWords[i].wordCount}</li>`;
      }

      let img1 =
        "https://www.sunset.com/wp-content/uploads/readers-choice-best-bridge-golden-gate-getty-462144413-0819.jpg";
      //let img2 = "https://picsum.photos/id/1005/400/250";

      //:src="require(`@/assets/roles/${thisParticipant.rolePic}`)"

      //let img1 = "@/assets/images/golden-gate-bridge.jpg";

      fillerwordsReportComponent += "</ul>";
      return `<div class="card shadow-xl image-full"><figure><img src="${img1}"/></figure> <div class="justify-end card-body"><h1 class="card-title">${role}</h1> <h3 class="card-title">${name}</h3> <hr class="mt-3 mb-3" />${fillerwordsReportComponent}<div class="card-actions"></div></div></div>`;
    }

    return {
      state,
      caps,
      roles,
      fillerWords,
      openFunctionaryPrompt,
      closeFunctionaryPrompt,
      addUser,
      roleImageMap,
      handleOpenCounterTallyModalEvent,
      closeTallyModal,
      decreaseFillerwordTally,
      increaseFillerwordTally,
      delUser,
      openAhCounterReportModal,
    };
  },
});
</script>
