export const fillerWords = [
	{
		word: "ah",
		wordCount: 0,
	},
	{
		word: "um",
		wordCount: 0,
	},
	{
		word: "double",
		wordCount: 0,
	},
	{
		word: "and",
		wordCount: 0,
	},
	{
		word: "so",
		wordCount: 0,
	},
	{
		word: "you know",
		wordCount: 0,
	},
	{
		word: "but",
		wordCount: 0,
	},
	{
		word: "other",
		wordCount: 0,
	},
];
