import { createStore } from "vuex";
import { Auth } from "./modules/Auth";
import { Timer } from "./modules/Timer";
import { Counter } from "./modules/Counter";
import { Roles } from "./modules/Roles";
import { Users } from "./modules/Users";
import { RoleMap } from "./modules/RoleMap";
import { Functionary } from "./modules/Functionary";
import { Captions } from "./modules/Captions";

export default createStore({
	state: {},

	mutations: {},

	actions: {},

	modules: {
		Auth,
		Timer,
		Counter,
		Users,
		Roles,
		RoleMap,
		Functionary,
		Captions,
	},
});
