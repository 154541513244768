<template>
  <base-layout-app>
    <template v-slot:app-body>
      <section class="text-gray-600 body-font">
        <div
          class="
            container
            px-5
            py-20
            mx-auto
            border
            rounded-2xl
            shadow
            bg-white
          "
        >
          <functionary-prompt-modal
            v-if="state.hasPromptOpen"
            :role="state.functionary"
            :prompt="state.tableTopicPrompt"
            :isOpen="state.hasPromptOpen"
            v-on:close-prompt-modal="closeFunctionaryPrompt"
          />

          <AnnotationIcon
            class="h-6 w-6 text-purple-600 absolute top-0 right-0 h-16 w-16"
            style="cursor: pointer"
            aria-hidden="true"
            @click="openFunctionaryPrompt"
          />
          <div class="flex flex-col text-center w-full mb-10">
            <h1
              class="
                sm:text-3xl
                text-2xl
                font-medium
                title-font
                mb-4
                text-purple-700
              "
            >
              Table Topics
            </h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
              {{ caps["/apps/table-topics"]["description"] }}
            </p>
          </div>

          <warning-modal
            v-if="state.isOpen"
            :subject="state.subject"
            :description="state.description"
            :isOpen="state.isOpen"
            v-on:close-modal="closeModal"
          />

          <div class="overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3 lg:col-span-4">
                  <label
                    for="topic"
                    class="block text-sm font-medium text-gray-700"
                    >Table Topic</label
                  >
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Type in your topic here..."
                    class="
                      mt-1
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      shadow-sm
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                    v-model="topic"
                    required
                  />
                </div>
                <div
                  class="
                    col-span-6
                    sm:col-span-3
                    md:col-span-6
                    lg:col-span-2
                    pt-5
                  "
                >
                  <button
                    class="
                      mt-1
                      block
                      w-full
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-indigo-600
                      hover:bg-indigo-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    @click="addTopic"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap mt-5" v-if="state.cards.length != 0">
              <ul class="topic-list">
                <li
                  class="
                    transition
                    duration-600
                    ease-in-out
                    transform
                    hover:scale-110
                  "
                  v-on:click="flipCard(card)"
                  v-for="(card, index) in state.cards"
                  :key="index"
                  :class="{ isFlipped: card.flipped }"
                >
                  <div class="card" name="flip">
                    <span v-if="!card.flipped" class="card-number">{{
                      index + 1
                    }}</span>
                    <p
                      v-bind:key="card.flipped"
                      :class="{ mirror: card.flipped }"
                    >
                      {{ card.flipped ? card.back : card.front }}
                      <span
                        v-if="!card.flipped"
                        v-on:click="deleteCard(index)"
                        class="delete-card"
                        >X</span
                      >
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </template>
  </base-layout-app>
</template>

<script>
import { defineComponent, reactive } from "vue";
import WarningModal from "../../components/apps/WarningModal.vue";
import BaseLayoutApp from "@/layouts/BaseLayoutApp.vue";
import { AnnotationIcon } from "@heroicons/vue/solid";
import { computed } from "vue";
import { useStore } from "vuex";
import FunctionaryPromptModal from "@/components/apps/FunctionaryPromptModal.vue";
export default defineComponent({
  name: "TableTopics",
  components: {
    WarningModal,
    BaseLayoutApp,
    AnnotationIcon,
    FunctionaryPromptModal,
  },
  setup() {
    let topic = "";
    const store = useStore();
    const caps = store.state.Captions.captions;
    const state = reactive({
      cards: [],
      subject: "Warning: Invalid Table Topic",
      description:
        "Please make sure that you enter a description in the Table Topic field",
      isOpen: false,
      hasPromptOpen: false,
      functionary: "Table Topic",
      tableTopicPrompt: computed(
        () => store.state.Functionary.prompts["tableTopic"]
      ),
    });

    function flipCard(card) {
      card.flipped = !card.flipped;
    }

    function deleteCard(index) {
      state.cards.splice(index, 1);
    }

    function addTopic() {
      if (this.topic === "" || this.topic.length < 1) {
        state.isOpen = true;
        return;
      }
      state.cards.push({
        front: "?",
        back: this.topic,
        flipped: false,
      });

      // randomize cards
      if (state.cards.length > 1) {
        var currentIndex = state.cards.length,
          temporaryValue,
          randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;

          // And swap it with the current element.
          temporaryValue = state.cards[currentIndex];
          state.cards[currentIndex] = state.cards[randomIndex];
          state.cards[randomIndex] = temporaryValue;
        }
      }

      // set topic back to empty
      this.topic = "";
    }
    function closeModal() {
      state.isOpen = false;
    }

    function openFunctionaryPrompt() {
      state.hasPromptOpen = true;
    }

    function closeFunctionaryPrompt() {
      state.hasPromptOpen = false;
      console.log("clicked closed");
    }

    return {
      topic,
      state,
      caps,
      closeModal,
      openFunctionaryPrompt,
      closeFunctionaryPrompt,
      flipCard,
      deleteCard,
      addTopic,
    };
  },
});
</script>

<style scoped>
main {
  font-family: "Poppins", sans-serif;
}
ul {
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
}

li {
  list-style-type: none;
  padding: 10px 10px;
  transition: all 0.3s ease;
}

.container {
  max-width: 100%;
  padding: 2em;
}

.card {
  display: flex;
  width: 250px;
  height: 275px;
  justify-content: center;
  align-items: center;
  background-color: #51aae5;
  border-radius: 7px;
  margin: 5px;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  -webkit-box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, 0.5);
  -moz-box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, 0.5);
  box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, 0.5);
  will-change: transform;
}

li:nth-child(-n + 3) .card {
  background-color: #e65f51;
}

li:nth-child(2n + 1) .card {
  background-color: #a17de9;
}

li:nth-child(4n) .card {
  background-color: #feca34;
}

li:nth-child(5n-2) .card {
  background-color: #51aae5;
}

li:nth-child(4n + 4) .card {
  background-color: #feca34;
}

li:nth-child(-7n + 7) .card {
  background-color: #e46055;
}

.delete-card {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 15px;
  opacity: 0.4;
  transition: all 0.5s ease;
}
.card-number {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px 15px;
  opacity: 0.8;
}
.delete-card:hover,
.error {
  opacity: 1;
  transform: rotate(360deg);
}

.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}

.isFlipped {
  transform: rotateY(180deg);
  transition: transform 0.5s;
  display: inline-block;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.mirror {
  display: inline-block;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
  -o-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
</style>
