<template>
  <base-layout-app>
    <template v-slot:app-body>
      <grammarian-initializer-modal
        v-if="state.isOpen"
        :subject="state.subject"
        :isOpen="state.isOpen"
        :invalidWord="state.isInvalid"
        v-on:enter-grammarian-word="handleEnterEvent"
      />

      <functionary-prompt-modal
        v-if="state.hasPromptOpen && !state.isOpen"
        :role="state.functionary"
        :prompt="state.grammarianPrompt"
        :isOpen="state.hasPromptOpen"
        v-on:close-prompt-modal="closeFunctionaryPrompt"
      />

      <AnnotationIcon
        class="h-6 w-6 text-purple-600 absolute top-0 right-0 h-16 w-16"
        style="cursor: pointer"
        aria-hidden="true"
        @click="openFunctionaryPrompt"
      />

      <div
        class="container px-5 py-10 mx-auto border rounded-2xl shadow bg-white"
      >
        <div class="flex flex-col text-center w-full mb-10">
          <h1
            class="
              sm:text-3xl
              text-2xl
              font-medium
              title-font
              mb-4
              text-purple-700
            "
          >
            Grammarian App
          </h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
            {{ caps["/apps/grammarian"]["description"] }}
          </p>
        </div>
        <word-definition-card
          v-if="!state.isOpen"
          :definitionResponse="state.word"
        />
        <div class="overflow-hidden sm:rounded-md">
          <div class="px-4 py-5 bg-white sm:p-6">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                  >Name</label
                >
                <input
                  type="text"
                  name="name"
                  id="name"
                  autocomplete="full-name"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  v-model="name"
                />
              </div>
              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  for="role"
                  class="block text-sm font-medium text-gray-700"
                  >Role</label
                >
                <select
                  id="role"
                  name="role"
                  autocomplete="role"
                  class="
                    mt-1
                    block
                    w-full
                    py-2
                    px-3
                    border border-gray-300
                    bg-white
                    rounded-md
                    shadow-sm
                    focus:outline-none
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    sm:text-sm
                  "
                  v-model="role"
                >
                  <option v-for="role in roles" :key="role.id" :value="role">
                    {{ role.data.role }}
                  </option>
                </select>
              </div>
              <div
                class="
                  col-span-6
                  sm:col-span-3
                  md:col-span-6
                  lg:col-span-2
                  pt-5
                "
              >
                <button
                  class="
                    mt-1
                    block
                    w-full
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    shadow-sm
                    text-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-indigo-600
                    hover:bg-indigo-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                  @click="addUser"
                >
                  Add user
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="state.users.length !== 0" class="flex flex-wrap">
          <grammarian-card
            v-for="(user, index) in state.users"
            :key="index"
            :name="user.name"
            :role="user.role"
            :wordCount="user.grammarianWord"
            @grammarian-increment="handleGrammarianIncrementEvent(user.id)"
            @grammarian-decrement="handleGrammarianDecrementEvent(user.id)"
            @del-user="delUser(user.id)"
          />
        </div>
      </div>
    </template>
  </base-layout-app>
</template>

<script>
import { defineComponent, reactive } from "vue";
import BaseLayoutApp from "@/layouts/BaseLayoutApp.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import GrammarianCard from "@/components/apps/GrammarianCard.vue";
import GrammarianInitializerModal from "@/components/GrammarianInitializerModal.vue";
import WordDefinitionCard from "../../components/apps/WordDefinitionCard.vue";
import FunctionaryPromptModal from "@/components/apps/FunctionaryPromptModal.vue";
import { AnnotationIcon } from "@heroicons/vue/solid";
export default defineComponent({
  name: "AppGrammarian",
  components: {
    BaseLayoutApp,
    GrammarianCard,
    GrammarianInitializerModal,
    FunctionaryPromptModal,
    AnnotationIcon,
    WordDefinitionCard,
  },
  setup() {
    const store = useStore();
    const roles = computed(() => store.state.RoleMap.roles);
    const caps = store.state.Captions.captions;
    const state = reactive({
      grammarianPrompt: computed(
        () => store.state.Functionary.prompts["grammarian"]
      ),
      isOpen: true,
      isInvalid: false,
      word: null,
      subject: "Please enter your word",
      name: "",
      hasPromptOpen: false,
      functionary: "Grammarian",
      users: computed(() => store.state.Users.users),
    });

    function addUser() {
      let roleObj = JSON.parse(JSON.stringify(this.role));
      let user = { name: this.name, role: roleObj["data"], grammarianWord: 0 };
      store.dispatch("Users/addUser", user);
      this.name = "";
      this.role = {};
    }

    function delUser(id) {
      store.dispatch("Users/deleteUser", id);
    }

    function handleEnterEvent(event) {
      if (typeof event === "object") {
        state.isOpen = false;
        state.word = event;
      } else if (typeof event === "boolean") {
        state.isInvalid = event;
      }
    }

    function openFunctionaryPrompt() {
      state.hasPromptOpen = true;
    }

    function closeFunctionaryPrompt() {
      state.hasPromptOpen = false;
    }

    function handleGrammarianIncrementEvent(userId) {
      store.dispatch("Users/increaseGrammarianWords", userId);
    }

    function handleGrammarianDecrementEvent(userId) {
      store.dispatch("Users/decreaseGrammarianWords", userId);
    }

    return {
      roles,
      state,
      caps,
      addUser,
      delUser,
      handleEnterEvent,
      openFunctionaryPrompt,
      closeFunctionaryPrompt,
      handleGrammarianIncrementEvent,
      handleGrammarianDecrementEvent,
    };
  },
});
</script>

<style scoped>
main {
  font-family: "Poppins", sans-serif;
}
</style>