export const functionaryPrompts = {
	tableTopic: [
		"Thank you, Toastmaster; greetings, fellow Toastmasters!",
		"One of the most exciting portions of the Toastmasters meetings is the Table Topics Session. The main objective is to challenge members to develop their impromptu communications skills, to effectively “think on your feet” by answering unrehearsed questions.",
		"We also want to give every member the opportunity to speak at each meeting. We do this with the Table Topics Session.",
		"Table Topics is specifically designed to develop four essential communications skills - 'Listening,' 'Thinking,' 'Organizing' and 'Speaking.'",
		"Each speaker is encouraged to use the Word of the Day. I will give a Word of the Day report and grammatical usage report when called upon during the meeting.",
		"Thank you, Mr./Madam Toastmaster.",
		"Today, as your Table Topics Master, I will be asking questions related to our [Meeting theme or Table Topics theme].",
		"Your response must be at least one (1) minute and can be no more than two (2) minutes. You will see a Green signal after 1 minute (your minimum time), a Yellow signal at 1:30, and a Red signal at two (2) minutes.",
		"I also encourage you to use the Word of the Day: [Word of the Day]",
		"So let’s get started, who wants to be first?",
	],

	grammarian: [
		"Mr./Madam Toastmaster, fellow Toastmasters, and guests.",
		"As Grammarian, it is my responsibility to pay close attention to all speakers, listening carefully to their language usage.",
		"I’ll take note of any misuses of the English language, as well as any outstanding words, quotes, sayings, or thoughts.",
		"As Grammarian, it is also my duty to introduce the Word of the Day.",
		"For today’s meeting, the Word is ________________, which means ____________________.[Display the Word of the Day at the front of the room.] •An example of using the word is: ________________________________.",
		"Each speaker is encouraged to use the Word of the Day. I will give a Word of the Day report and grammatical usage report when called upon during the meeting.",
		"Thank you, Mr./Madam Toastmaster.",
	],

	ahCounter: [
		"Greetings Mr./Madam Toastmaster, fellow Toastmasters, and guests",
		"The purpose of the Ah-Counter is to note words and sounds that are used as a “crutch” or “pause filler” by anyone who speaks.",
		"During the meeting, I will listen for overused words, including and, well, but, so, and you know.",
		"I will also listen for filler sounds, including ah, um, and er.",
		"I will also note when a speaker repeats a word or phrase, such as “I, I” or “This means, this means.”",
		"At the end of the meeting, I will report the number of times that each speaker used these expressions.",
		"Thank you, Mr. /Madam Toastmaster.",
	],

	timer: [
		"Greetings Mr./Madam Toastmaster, fellow Toastmasters, and guests.",
		"As Timer, I will time the Table Topics® speakers, formal speeches, and the evaluations.",
		"I will also alert each speaker of the time they have left, using reminders during the meeting if they start approaching their time limits.",
		"As a reminder for speech lengths:",
		"• Table Topics speakers should limit their remarks to no more than 2 minutes.",
		"• Prepared speeches: Ice Breakers speeches should be 4-6 minutes in length and most other speeches should be 5-7 minutes in length unless otherwise indicated by the project.",
		"• Speaker evaluations should be between 2-3 minutes.",
		"Thank you Mr./Madam Toastmaster.",
	],
};
