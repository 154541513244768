export const Functionary = {
	namespaced: true, // this allows you to reference this module by its name Counter

	state: {
		prompts: null,
	},

	mutations: {
		SET_PROMPTS(state, prompts) {
			state.prompts = prompts;
		},
	},
	actions: {
		setPrompts({ commit }, prompts) {
			commit("SET_PROMPTS", prompts);
		},
	},
};
