//import { createApp } from "vue";
import { createApp } from "vue/dist/vue.esm-bundler";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "tailwindcss/tailwind.css";

/*
import 'bootstrap'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
*/
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import {
	applyPolyfills,
	defineCustomElements,
} from "@aws-amplify/ui-components/loader";

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
	defineCustomElements(window);
});

const app = createApp(App);

//app.config.isCustomElement = tag => tag.startsWith('amplify-')
app.config.compilerOptions.isCustomElement = (tag) =>
	tag.startsWith("amplify-");

app.use(store);
app.use(router);
app.mount("#app");
