// use static fillerWords as default value if fillerWords are not fetched by future APIs
// import { fillerWords } from '@/assets/fillerWords'

export const roles = {
	currentRoleIndex: -1,
	participants: [
		{
			id: "1",
			name: "Frad",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "2",
			name: "Thiha",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "3",
			name: "Carla",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "4",
			name: "Mia",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "5",
			name: "Curry",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "6",
			name: "Jimmy",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "7",
			name: "francis",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "8",
			name: "Shariff",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "9",
			name: "jim",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "10",
			name: "Matt",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "11",
			name: "George",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
		{
			id: "12",
			name: "Alfred",
			fillerWords: [
				{
					word: "ah",
					wordCount: 0,
				},
				{
					word: "um",
					wordCount: 0,
				},
				{
					word: "like",
					wordCount: 0,
				},
				{
					word: "double",
					wordCount: 0,
				},
				{
					word: "and",
					wordCount: 0,
				},
				{
					word: "so",
					wordCount: 0,
				},
				{
					word: "you know",
					wordCount: 0,
				},
				{
					word: "but",
					wordCount: 0,
				},
				{
					word: "other",
					wordCount: 0,
				},
			],
			isSelected: false,
		},
	],
};
