<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="open"
      :open="open"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
            "
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <img
                  :src="require(`@/assets/roles/${thisParticipant.rolePic}`)"
                  class="block h-48 w-auto rounded content-center"
                />
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-gray-900"
                  >
                    {{ thisParticipant.role.role }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p>{{ thisParticipant.name }}</p>
                  </div>
                </div>
              </div>
              <counter
                v-for="(fillerWord, card_index) in thisParticipant.fillerWords"
                :key="card_index"
                :word="fillerWord.word"
                :wordCount="fillerWord.wordCount"
                v-on:increment-fillerword-count="
                  incrementFillerWord(thisParticipant, card_index)
                "
                v-on:decrement-fillerword-count="
                  decrementFillerWord(thisParticipant, card_index)
                "
              />
            </div>
            <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
            >
              <button
                type="button"
                class="
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-purple-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-red-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-red-500
                  sm:ml-3 sm:w-auto sm:text-sm
                "
                @click="closeAhTallyModal"
              >
                Close
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { toRefs } from "vue";

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import Counter from "@/components/Counter.vue";

export default {
  name: "FunctionaryPromptModal",
  props: {
    participant: {
      type: Object,
      required: true,
    },
    isTallyBoardOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Counter,
  },
  setup(props, context) {
    let parentProps = toRefs(props);
    // get all parent properties
    let isOpen = parentProps.isTallyBoardOpen.value;
    let open = isOpen;

    let thisParticipant = parentProps.participant.value;

    function closeAhTallyModal() {
      // emitting closing event from child to parent
      let parentProps = toRefs(props);
      this.open = parentProps.isTallyBoardOpen.value;
      context.emit("close-ah-tally-modal", "close");
    }

    function incrementFillerWord(participant, index) {
      let payload = {
        wordIndex: index,
        userId: participant.id,
      };
      context.emit("tally-ah-increment", payload);
    }

    function decrementFillerWord(participant, index) {
      let payload = {
        wordIndex: index,
        userId: participant.id,
      };
      context.emit("tally-ah-decrement", payload);
    }

    return {
      closeAhTallyModal,
      open,
      thisParticipant,
      Counter,
      decrementFillerWord,
      incrementFillerWord,
    };
  },
};
</script>