export const Captions = {
	namespaced: true,

	state: {
		captions: null,
	},

	mutations: {
		SET_CAPTIONS(state, captions) {
			state.captions = captions;
		},
	},
	getters: {
		getCaptionsByResourceEndpoint: (state) => (endpoint) => {
			return state.captions[endpoint];
		},
		getCaptions(state) {
			return state.captions;
		},
	},
	actions: {
		setCaptions({ commit }, captions) {
			commit("SET_CAPTIONS", captions);
		},
	},
};
