<template>
  <div class="p-2 lg:w-5/6 md:w-5/6 w-full relative">
    <div
      class="
        h-full
        flex flex-col
        items-center
        border-gray-200 border
        p-4
        rounded-lg
        shadow-lg
        bg-white
      "
    >
      <div class="grid grid-cols-3 w-full">
        <div class="col-span-2 mx-auto">
          <p class="text-purple-800 font-medium text-center">
            Word of the day:&nbsp;&nbsp;<strong>
              {{ definitionResponse.word }}
            </strong>
          </p>

          <div class="col-span-3">
            <hr class="mt-3 mb-3" />
            <p class="text-purple-800 title-font font-medium">
              <strong>Pronunciation</strong>
              <span v-if="definitionResponse.meanings.length > 1">s</span>
            </p>
            <ul>
              <li
                v-for="(phonetic, index) in definitionResponse.phonetics"
                :key="index"
              >
                <span>{{ ++index }}.&nbsp;&nbsp;&nbsp;{{ phonetic.text }}</span>
                &nbsp;&nbsp;
                <span v-if="phonetic.audio">
                  <VolumeUpIcon
                    class="h-6 w-6 text-grey-600"
                    style="cursor: pointer"
                    @click="playAudio(phonetic.audio)"
                  />
                </span>
              </li>
            </ul>
            <hr class="mt-3 mb-3" />
            <p class="text-purple-800 title-font font-medium">
              <strong>Definition</strong>
              <span v-if="definitionResponse.meanings.length > 1">s</span>
            </p>
            <ul>
              <li
                v-for="(meaning, meaningIndex) in definitionResponse.meanings"
                :key="meaningIndex"
              >
                &nbsp;&nbsp;&nbsp;<strong>Definition </strong>
                <span v-if="definitionResponse.meanings.length > 1">{{
                  ++meaningIndex
                }}</span
                >:
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Part of Speech:
                <i> {{ meaning.partOfSpeech }} </i> <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Definition:
                <i>{{ meaning.definitions[0].definition }} </i> <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Example:
                <i>{{ meaning.definitions[0].example }} </i> <br />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
//import { VolumeUpIcon } from "@vue-hero-icons/solid";
import { VolumeUpIcon } from "@heroicons/vue/solid";

export default defineComponent({
  name: "WordDefinitionCard",
  props: {
    definitionResponse: {
      type: Object,
      required: true,
    },
  },
  components: {
    VolumeUpIcon,
  },
  setup() {
    function playAudio(audioURL) {
      console.log(audioURL);
      var audio = new Audio(audioURL); // path to file
      audio.play();
    }

    return {
      playAudio,
    };
  },
});
</script>
