<template>
  <router-view />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Baloo+2&family=Montserrat&family=Poppins&family=Roboto+Slab&display=swap");
html,
body {
  font-family: "Baloo 2", cursive;
}
</style>
