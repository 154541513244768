<template>
  <div class="p-2 lg:w-1/4 md:w-1/2 w-full relative">
    <div class="absolute right-3 top-3">
      <button @click="$emit('del-ah-counter-user')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-red-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div
      class="
        h-full
        flex flex-col
        items-center
        border-gray-200 border
        p-4
        rounded-lg
        shadow-lg
        bg-white
      "
    >
      <div class="grid grid-cols-3 w-full">
        <div class="mx-auto">
          <img
            alt="profile-pic"
            class="
              w-16
              h-16
              bg-gray-100
              object-cover object-center
              flex-shrink-0
              rounded-full
            "
            :src="require(`@/assets/roles/profile-pic.svg`)"
          />
        </div>
        <div class="col-span-2 mx-auto">
          <p class="text-gray-800 title-font font-medium text-center">
            {{ name }}
          </p>
          <p class="text-gray-400 title-font font-normal text-center">
            {{ role.role }}
          </p>
        </div>
        <div class="col-span-3">
          <hr class="mt-3 mb-3" />
          <div class="flex">
            <button
              type="button"
              class="
                flex-1
                mx-2
                transition
                duration-300
                bg-purple-500
                ring ring-purple-600
                rounded-full
                text-lg
                shadow-lg
                text-white
                hover:bg-indigo-600
              "
              @click="onOpenCounterTallyModal"
            >
              <i class="far fa-id-card"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs } from "vue";

export default defineComponent({
  name: "AhCounteCard",
  props: {
    name: {
      type: String,
      required: true,
    },
    role: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    let parentProps = toRefs(props);
    let id = parentProps.id.value;
    function onOpenCounterTallyModal() {
      console.log("The id is-->", id);
      context.emit("openCounterTallyModalEvent", id);
    }
    return {
      onOpenCounterTallyModal,
    };
  },
});
</script>